<template>
  <Container>
    <v-form
      ref="loginIn"
      @submit.prevent="signInNewTab">
      <label class="sourcery__form">
        Email*
        <v-text-field
          v-model="initialCaseEmail"
          :append-icon="!!emailErrors.length? 'mdi-alert-circle-outline' : ''"
          :error="!!emailErrors.length"
          :error-messages="emailErrors"
          :height="48"
          autocomplete="email"
          class="mt-2"
          data-test="sign_in_email_input"
          name="login-email-address"
          type="email"
          dense
          outlined
          single-line
          @input="clearLoginErrorMessage('email')" />
      </label>
      <label class="sourcery__form">
        Password*
        <v-text-field
          v-model="login.password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :error="!!passwordErrors.length"
          :error-messages="passwordErrors"
          :height="48"
          :type="showPassword ? 'text' : 'password'"
          autocomplete="current-password"
          class="mt-2"
          data-test="sign_in_password_input"
          name="login-password"
          dense
          outlined
          single-line
          @click:append="showPassword = !showPassword"
          @input="clearLoginErrorMessage('password')" />
      </label>

      <v-btn
        :disabled="showSpinner"
        :elevation="0"
        class="white--text sourcery__big-pill"
        color="blue"
        data-test="log_in_button"
        type="submit"
        block>
        Log In
      </v-btn>

      <router-link
        :to="{ name: 'white-label-forgot' }"
        class="d-block mt-6 fs-16 black--text text-center text-decoration-underline lh-normal"
        data-test="forgot_your_password_button">
        Forgot your password?
      </router-link>
    </v-form>
  </Container>
</template>
<script>
import {
  required, email, minLength,
} from 'vuelidate/lib/validators';
import AWS from '@/services/awsSdk';
import API from '@/services/graphql';
import ConverEmailToLowerCase from '@/mixins/ConverEmailToLowerCase';
import ErrorsText from '@/constants/errors';
import {
  mapMutations, mapActions, mapState,
} from 'vuex';
export default {
  name: 'WhiteLabelLogin',
  components: {
    Container: () => import('@/views/Agent/Container'),
  },
  mixins: [ConverEmailToLowerCase],
  props: {
    email: {
      type: String,
      default: null,
    },
    merged: {
      type: Boolean,
      default: false,
    },
    loginInvitedUser: {
      type: Boolean,
      default: false,
    },
    forceLoginFlag: {
      type: Boolean,
      default: false,
    },
    password: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    error: null,
    showPassword: false,
    pageObjName: 'login',
    login: {
      email: null,
      password: null,
      initialCaseEmail: null,
    },
    hasOngoingLogin: false,
  }),
  computed: {
    ...mapState({
      showSpinner: state => state.showSpinner,
    }),
    emailErrors() {
      const errors = [];
      if (!this.$v.login.email.$dirty) return errors;
      if (!this.$v.login.email.email) {
        errors.push('Invalid e-mail address');
      }
      if (!this.$v.login.email.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error && !this.error.includes('password')) {
        errors.push(this.error);
      }
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.login.password.$dirty) return errors;
      if (!this.$v.login.password.minLength) {
        errors.push('Passwords must be at least 8 characters long');
      }
      if (!this.$v.login.password.required) {
        errors.push(ErrorsText.ENTER_REQUIRED_VALUE);
      }
      if (this.error && this.error.includes('password')) {
        this.error == ErrorsText.ERROR_WITH_USERNAME_OR_PASSWORD ? errors.push(ErrorsText.ERROR_WITH_PASSWORD) : errors.push(this.error);
      }
      return errors;
    },
  },
  mounted() {
    const { email, password, forceLoginFlag } = this;
    if (forceLoginFlag) {
      this.forceLogin({
        email,
        password,
      });
    }
  },
  validations: {
    login: {
      email: {
        required, email,
      },
      password: {
        required, minLength: minLength(8),
      },
    },
  },
  async created() {
    this.clearListOfFilesForSubscriptions();
    this.clearListOfFilesToNotify();
    if (this.email) {
      this.initialCaseEmail = this.email;
    }
    const { uuid, email, password } = this.$route.query;
    this.setLoginRedirectFromQuery();
    if (uuid) {
      this.getTemporaryCredentials(uuid);
    } else if (email && password) {
      this.forceLogin({
        email, password,
      });
    }
  },
  methods: {
    ...mapMutations({
      spinner: 'spinner',
      clearListOfFilesForSubscriptions: 'ExportSchedule/clearListOfFilesForSubscriptions',
      clearListOfFilesToNotify: 'ExportSchedule/clearListOfFilesToNotify',
    }),
    ...mapActions({
      featureFlags: 'FeatureFlags/getListFeatureFlags',
    }),
    clearLoginErrorMessage() {
      this.error = null;
    },
    async getTemporaryCredentials(uuid) {
      try {
        const { data } = await API.getTemporaryCredentials({
          uuid,
        });
        if (data?.response) {
          this.forceLogin(data.response);
        }
      } catch (err) {
        console.log(err);
      }
    },
    forceLogin({ email, password } = {
    }) {
      if (email && password) {
        this.initialCaseEmail = email;
        this.login = {
          ...this.login,
          password,
        };
        this.signIn();
      }
    },
    async signIn() {
      if (this.hasOngoingLogin) return;

      try {
        this.hasOngoingLogin = true;
        this.$v.login.$touch();
        if (!this.$v.login.$invalid) {
          this.spinner(true);
          const { email, password } = this.login;
          const dataToSend = {
            email,
            password,
          };
          await AWS.login(dataToSend, this);
        }
      } finally {
        this.hasOngoingLogin = false;
      }
    },
    signInNewTab() {
      const { email, password } = this.login;
      const { origin } = window.location;
      // encode uri to keep the special characters
      window.open(`${origin}/white-label?email=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`, '_blank');
      this.login = {
        email: null,
        password: null,
        initialCaseEmail: null,
      };
    },
    setLoginRedirectFromQuery() {
      const { resourceType, resourceId } = this.$route.query;
      if (!resourceType || !resourceId) return;
      sessionStorage.setItem('redirectFromQuery', true);
    },
  },
};
</script>
